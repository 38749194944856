import CloseIconComponent from '@components/icons/closeIcon';
import MenuIconComponent from '@components/icons/menuIcon';
import MimoWebLogo from '@components/icons/mimoWebLogo';
import { useMixpanel } from '@utils/tracking';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import PrimaryCTAComponent from './PrimaryCTA';
import { AppStoreBadgeComponent } from '@components/illustrations/AppStoreBadge';
import { PlayStoreBadgeComponent } from '@components/illustrations/PlayStoreBadge';
import TwitterLandingPageFooterIconComponent from '@components/icons/twitterLandingPageFooterIcon';
import FacebookLandingPageFooterIconComponent from '@components/icons/facebookLandingPageFooterIcon';
import InstagramLandingPageFooterIconComponent from '@components/icons/instagramLandingPageFooterIcon';
import TiktokLandingPageFooterIconComponent from '@components/icons/tiktokLandingPageFooterIcon';
import LinkedInLandingPageFooterIconComponent from '@components/icons/linkedInLandingPageFooterIcon';
import MediumLandingPageFooterIconComponent from '@components/icons/mediumLandingPageFooterIcon';
import { trackLinkToAppStore, trackLinkToPlayStore } from '@customtypes/events';
import { ButtonSmallText, Heading4, Heading5, BodyTextSmall } from './Texts';
import { NavBarConfetti } from './ConfettiBackground';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  MEDIUM_LINK,
  TIKTOK_LINK,
  TWITTER_LINK,
} from '@utils/constants';
import { getRegisterPageLink } from '@utils/link-utils-web';

interface NavbarProps {
  disableTransparentBackground?: boolean;
}

export default function Navbar({
  disableTransparentBackground = false,
}: NavbarProps) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const router = useRouter();
  const mixpanel = useMixpanel();

  useEffect(() => {
    setIsMenuOpened(false);
  }, [router.pathname, router.query]);

  const hideBar = () => {
    if (window.scrollY < 30) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', hideBar);
    return () => {
      window.removeEventListener('scroll', hideBar);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpened) {
      document.body.classList.add('navbar-body-overflow-hidden');
      document.body.style.position = 'relative';
    } else {
      document.body.classList.remove('navbar-body-overflow-hidden');
      document.body.style.position = 'static';
    }
  }, [isMenuOpened]);

  function trackPlayStoreLink() {
    trackLinkToPlayStore(mixpanel);
  }

  function trackAppStoreLink() {
    trackLinkToAppStore(mixpanel);
  }

  return (
    <div
      className={`${
        isMenuOpened || isSticky || disableTransparentBackground
          ? 'bg-product-background-primary-dark'
          : 'bg-transparent'
      } ${
        isSticky
          ? 'sticky top-0 animate-fade-in border-b-2 border-marketing-accent-darkBlue'
          : 'relative'
      } z-[1000] w-full`}
    >
      <div
        className={`flex h-[80px] w-full items-center justify-between px-6 py-4 text-marketing-text-primary tablet:px-10`}
      >
        <div className="flex tablet:flex-grow-0 desktop:flex-1">
          <Link href="/">
            <MimoWebLogo className="h-8" />
          </Link>
        </div>
        <div
          className={`hidden flex-1 space-x-10 tabletLg:mr-10 tabletLg:flex tabletLg:justify-end desktop:mr-0 desktop:flex desktop:justify-center desktop:space-x-10`}
        >
          <Link href={'/glossary/html/attributes'}>
            <ButtonSmallText className="whitespace-nowrap hover:text-marketing-button-primary-hover">
              Glossary
            </ButtonSmallText>
          </Link>
          <Link href={'/blog'}>
            <ButtonSmallText className="whitespace-nowrap hover:text-marketing-button-primary-hover">
              Blog
            </ButtonSmallText>
          </Link>
          <Link href={'/learner-stories'}>
            <ButtonSmallText className="whitespace-nowrap hover:text-marketing-button-primary-hover">
              Learner stories
            </ButtonSmallText>
          </Link>
          <Link href={'/pro'}>
            <ButtonSmallText className="whitespace-nowrap hover:text-marketing-button-primary-hover">
              Pricing
            </ButtonSmallText>
          </Link>
        </div>
        <div className="hidden items-center justify-end space-x-10 tabletLg:flex tabletLg:flex-grow-0 desktop:flex-1">
          <Link href={'/web/login'}>
            <ButtonSmallText className="hover:text-marketing-button-primary-hover">
              Log in
            </ButtonSmallText>
          </Link>
          <PrimaryCTAComponent
            href={getRegisterPageLink()}
            className="mobile-fallback__page-content rounded-xl bg-marketing-button-primary-enabled text-marketing-button-primary-text hover:bg-marketing-button-primary-hover"
            title="Sign up"
          ></PrimaryCTAComponent>
        </div>
        <button
          onClick={() => setIsMenuOpened(!isMenuOpened)}
          className="tabletLg:hidden"
        >
          {isMenuOpened ? (
            <CloseIconComponent className="h-10 w-10" />
          ) : (
            <MenuIconComponent className="h-10 w-10" />
          )}
        </button>
        <div
          className={`${
            isMenuOpened
              ? 'h-[calc(100vh-80px)] bg-marketing-background-secondary'
              : 'h-0 bg-marketing-background-secondary'
          } absolute bottom-0 left-0 right-0 top-[80px] z-40 flex flex-col justify-between overflow-y-auto transition-all duration-300 ease-out tabletLg:hidden`}
        >
          <div className="relative z-40 mx-10 mb-10 mt-16 flex h-full flex-1 flex-col items-start space-y-6 text-marketing-button-primary-text">
            <NavBarConfetti />

            <Link href={'/glossary/html/attributes'}>
              <Heading4 className="hover:text-marketing-button-primary-hover">
                Glossary
              </Heading4>
            </Link>
            <Link href={'/blog'}>
              <Heading4 className="hover:text-marketing-button-primary-hover">
                Blog
              </Heading4>
            </Link>
            <Link href={'/learner-stories'}>
              <Heading4 className="hover:text-marketing-button-primary-hover">
                Learner stories
              </Heading4>
            </Link>
            <Link href={'/pro'}>
              <Heading4 className="hover:text-marketing-button-primary-hover">
                Pricing
              </Heading4>
            </Link>
            <Link href={'/web/login'}>
              <Heading4 className="hover:text-marketing-button-primary-hover">
                Log in
              </Heading4>
            </Link>
            <Link
              href={getRegisterPageLink()}
              className="mobile-fallback__page-content"
            >
              <Heading4 className="hover:text-marketing-button-primary-hover">
                Create an account
              </Heading4>
            </Link>
          </div>
          <div className="z-40 mx-10 mb-20 mt-10 flex flex-col items-start space-y-10 text-marketing-button-primary-text">
            <div className="flex flex-col space-y-4">
              <Heading5>Download</Heading5>
              <div className="flex flex-wrap gap-4">
                <a
                  onClick={trackAppStoreLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://itunes.apple.com/us/app/mimo-learn-how-to-code-through/id1133960732?mt=8&at=1000lpyT"
                >
                  <AppStoreBadgeComponent className="h-12"></AppStoreBadgeComponent>
                </a>
                <a
                  onClick={trackPlayStoreLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.getmimo"
                >
                  <PlayStoreBadgeComponent className="h-12"></PlayStoreBadgeComponent>
                </a>
              </div>
            </div>
            <div className="space-y-4">
              <Heading5>Follow us</Heading5>
              <div className="flex flex-wrap gap-6">
                <a
                  title="Link to Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={TWITTER_LINK}
                  aria-label="Twitter"
                >
                  <TwitterLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></TwitterLandingPageFooterIconComponent>
                </a>
                <a
                  title="Link to Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={FACEBOOK_LINK}
                  aria-label="facebook"
                >
                  <FacebookLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></FacebookLandingPageFooterIconComponent>
                </a>
                <a
                  title="Link to Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={INSTAGRAM_LINK}
                  aria-label="Instagram"
                >
                  <InstagramLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></InstagramLandingPageFooterIconComponent>
                </a>
                <a
                  title="Link to TikTok"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={TIKTOK_LINK}
                  aria-label="TikTok"
                >
                  <TiktokLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></TiktokLandingPageFooterIconComponent>
                </a>
                <a
                  title="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={LINKEDIN_LINK}
                  aria-label="LinkedIn"
                >
                  <LinkedInLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></LinkedInLandingPageFooterIconComponent>
                </a>
                <a
                  title="Link to Medium"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={MEDIUM_LINK}
                  aria-label="Medium"
                >
                  <MediumLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></MediumLandingPageFooterIconComponent>
                </a>
              </div>
            </div>
            <BodyTextSmall className="text-marketing-text-secondary">
              @ {new Date().getFullYear()} Mimo GmbH
            </BodyTextSmall>
          </div>
        </div>
      </div>
    </div>
  );
}
